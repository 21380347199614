import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Box } from '@trmediaab/zebra';

import ErrorMessage from './ErrorMessage';

interface QueryResultProps<TData> {
  // TODO - would be nice to import this type
  // https://github.com/reduxjs/redux-toolkit/issues/1141
  queryResult: {
    data?: TData;
    error?: FetchBaseQueryError | SerializedError | undefined;
    isLoading: boolean;
    refetch: () => void;
  };
  render: (data: TData) => JSX.Element;
  wrap?: (
    children: JSX.Element,
    resultType: 'loading' | 'error' | 'data',
  ) => JSX.Element | null;
}

function identity<T>(value: T): T {
  return value;
}

// TODO - refine loading state
// TODO - support multiple query results
const QueryResult = <TData,>(props: QueryResultProps<TData>) => {
  const {
    queryResult: { data, error, isLoading, refetch },
    render,
    wrap = identity,
  } = props;

  if (isLoading) {
    return wrap(<Box>Laddar...</Box>, 'loading');
  }

  if (error != null || data == null) {
    return wrap(<ErrorMessage err={error} retry={refetch} />, 'error');
  }

  if (data != null) {
    return wrap(render(data), 'data');
  }

  return null;
};

export default QueryResult;
