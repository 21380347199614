import { Box } from '@trmediaab/zebra';
import { forwardRef } from 'react';
import { useTheme } from 'styled-components';

type Props = React.ComponentProps<typeof Box>;

const Bleed = forwardRef<HTMLDivElement, Props>(
  ({ children, ...props }, ref) => {
    const {
      space: { gutters },
    } = useTheme();

    return (
      // @ts-expect-error TODO Property 'base' does not exist on type 'SpaceProperty | ObjectOrArray<SpaceProperty, string | number | symbol>'.
      <Box ref={ref} mx={[gutters.base * -1, null, 0]} {...props}>
        {children}
      </Box>
    );
  },
);

export default Bleed;
