import { Box, FlexBox, Text } from '@trmediaab/zebra';

interface TextWithAdjacentIconProps extends React.ComponentProps<typeof Text> {
  icon: React.ReactNode;
  text: React.ReactNode;
  gap?: string | number;
}

const TextWithAdjacentIcon = ({
  icon,
  text,
  gap = 1,
  ...props
}: TextWithAdjacentIconProps) => (
  <Text display="flex" alignItems="flex-start" {...props}>
    <FlexBox
      alignItems="center"
      flexShrink="0"
      mr={gap}
      css={`
        /* Hack to align icon with text on both single and multiple lines */
        &::before {
          content: 'X';
          width: 0px;
          visibility: hidden;
        }
      `}
    >
      {icon}
    </FlexBox>
    <Box flex="1">{text}</Box>
  </Text>
);

export default TextWithAdjacentIcon;
