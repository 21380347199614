import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Anchor, Box, Button } from '@trmediaab/zebra';

import Alert from '../Alert';

type Props = {
  err: FetchBaseQueryError | SerializedError | undefined;
  retry?: () => void;
};

const ErrorMessage = ({ err, retry }: Props) => {
  let title;
  let friendlyErrorMessage = '';
  let technicalErrorMessage = '';
  let showTechnicalErrorMessage = true;

  if (err != null) {
    // FetchBaseQueryError
    if ('status' in err) {
      let status = -1;

      if (err.status === 'PARSING_ERROR') {
        status = err.originalStatus;
      } else if (typeof err.status === 'number') {
        status = err.status;
      }

      switch (status) {
        case 401:
          title = 'Inte inloggad?';
          friendlyErrorMessage = 'Logga in och försök igen.';
          showTechnicalErrorMessage = false;
          break;
        case 400:
        case 403:
          friendlyErrorMessage = 'Hoppsan, nu gick det snett.';
          break;
        case 404:
          title = 'Tomt var det här';
          friendlyErrorMessage = 'Innehållet gick inte att hitta.';
          break;
        case 500:
          title = 'Ett serverfel har inträffat';
          friendlyErrorMessage = 'Oj, nu har vi strulat till det.';
          technicalErrorMessage = '500 Internal Server Error.';
          break;
        default:
          break;
      }

      technicalErrorMessage += ` ${
        'error' in err ? err.error : JSON.stringify(err.data)
      }`;
      // SerializedError
    } else {
      technicalErrorMessage = `SeralizedError: ${err.name} ${err.message} ${err.code}`;
    }
  } else {
    title = 'Ett okänt fel inträffade';
    friendlyErrorMessage = 'Oväntat fel, vänligen försök igen.';
  }

  if (friendlyErrorMessage.length > 0) {
    friendlyErrorMessage += ' ';
  }

  return (
    <Alert
      type="error"
      title={title}
      technicalErrorMessage={
        showTechnicalErrorMessage ? technicalErrorMessage : undefined
      }
    >
      {friendlyErrorMessage}
      Kontakta gärna{' '}
      <Anchor href="mailto:support@spelvarde.se" variant="matchTextUnderlined">
        supporten
      </Anchor>{' '}
      om problemet kvarstår.
      <Box mt="1">
        <Button
          colorScheme="anchorLike.matchText"
          textDecoration="underline"
          fontWeight="bold"
          onClick={retry != null ? retry : () => document.location.reload()}
        >
          {retry != null ? 'Försök igen' : 'Ladda om sidan'}
        </Button>
      </Box>
    </Alert>
  );
};

export default ErrorMessage;
