import { BorderBox, Box, Text } from '@trmediaab/zebra';

import Info from '~/icons/Info';

import TextWithAdjacentIcon from '../TextWithAdjacentIcon';

type AlertProps = React.ComponentProps<typeof BorderBox> & {
  title?: string | null;
} & (
    | {
        type: 'info' | 'noContent' | 'success';
        children?: React.ReactNode;
      }
    | {
        type: 'error';
        children: React.ReactNode;
        technicalErrorMessage?: React.ReactNode;
      }
  );

const typeMap: Record<
  AlertProps['type'],
  {
    title?: string;
    iconColor?: string;
    borderColor: string;
    bg: string;
  }
> = {
  info: {
    borderColor: 'border',
    bg: 'white',
  },
  noContent: {
    title: 'Inget att visa',
    borderColor: 'darkBlue',
    bg: 'background.light',
  },
  success: {
    iconColor: 'green',
    borderColor: 'green',
    bg: 'lightGreen',
  },
  error: {
    title: 'Ett fel inträffade',
    borderColor: 'primary',
    bg: 'primaryLightened',
  },
};

const Alert = ({
  type,
  title,
  technicalErrorMessage,
  children,
  ...props
}: AlertProps) => {
  const {
    title: defaultTitle,
    iconColor = 'primary',
    borderColor,
    bg,
  } = typeMap[type];
  const displayTitle = title === null ? null : title || defaultTitle;

  if (displayTitle == null && children == null) {
    console.warn(
      `Alert has nothing to display for ${type}. Provide a title, children or both to the component`,
    );
    return null;
  }

  const content =
    displayTitle == null ? (
      <Box maxWidth="640px">{children}</Box>
    ) : children == null ? (
      <Text.strong>{displayTitle}</Text.strong>
    ) : (
      <Box>
        <Text.strong display="block">{displayTitle}</Text.strong>
        <Box maxWidth="640px">{children}</Box>
        {technicalErrorMessage != null && (
          <Text
            as="details"
            mt="2"
            fontFamily="monospace"
            fontSize={[0, null, 1]}
            textAlign="right"
          >
            <Text
              as="summary"
              css={`
                cursor: pointer;
                list-style: none;
                user-select: none;
              `}
            >
              Teknisk info
            </Text>
            <Text
              css={`
                word-break: break-all;
              `}
            >
              {technicalErrorMessage}
            </Text>
          </Text>
        )}
      </Box>
    );

  return (
    <BorderBox
      border="1"
      borderRadius="1"
      borderColor={borderColor}
      bg={bg}
      pl="3"
      pr="3,5"
      py="2,5"
      sx={type === 'info' ? { fontSize: [1, null, 2] } : undefined}
      {...props}
    >
      <TextWithAdjacentIcon
        icon={
          <Info color={iconColor} width={type === 'info' ? '14px' : '16px'} />
        }
        text={content}
        gap="2"
      />
    </BorderBox>
  );
};

export default Alert;
