import { BetType } from '~/types/models/BetType';
import { BettingTrend } from '~/types/models/round/BettingTrend';
import { Calendar } from '~/types/models/round/Calendar';
import { Changes } from '~/types/models/round/Changes';
import { FrontFavoriteAnalysis } from '~/types/models/round/FrontFavoritesAnalysis';
import { GamePercentHistory } from '~/types/models/round/GamePercent';
import { ActiveRound, Round } from '~/types/models/round/Round';
import { RoundLeg } from '~/types/models/round/RoundLeg';
import {
  DetailedStart,
  StartHeadTohead,
  StartStatisticalTexts,
  StartStatistics,
} from '~/types/models/round/Start';
import { IdQueryArgs } from '~/types/models/utils';

import { traisApi } from '.';

type RoundQueryArgs = {
  roundSlug: string;
};

type RoundLegQueryArgs = {
  legSlug: string;
};

type GamePercentHistoryQueryArgs = IdQueryArgs &
  RoundLegQueryArgs & {
    betType: BetType;
  };

const roundApi = traisApi.injectEndpoints({
  endpoints: builder => ({
    // Round APIs
    getActiveRounds: builder.query<ActiveRound[], void>({
      query: () => 'round/active.json',
    }),
    getRound: builder.query<Round, RoundQueryArgs>({
      query: ({ roundSlug }) => `round/${roundSlug}.json`,
    }),
    getChanges: builder.query<Changes, RoundQueryArgs>({
      query: ({ roundSlug }) => `round/${roundSlug}/changes.json`,
    }),
    getBettingTrend: builder.query<BettingTrend[], RoundQueryArgs>({
      query: ({ roundSlug }) => `round/${roundSlug}/trend/gamepercent.json`,
      transformResponse(bettingTrend: BettingTrend[]) {
        return bettingTrend.sort((a, b) => {
          const aVal = a.period_total_diff ?? Number.MIN_VALUE;
          const bVal = b.period_total_diff ?? Number.MIN_VALUE;
          return bVal - aVal;
        });
      },
    }),
    getCalendar: builder.query<Calendar, { betType: BetType }>({
      query: ({ betType }) => `round/calendar/${betType}.json`,
    }),
    // Leg APIs
    getRoundLeg: builder.query<RoundLeg, RoundLegQueryArgs>({
      query: ({ legSlug }) => `roundleg/${legSlug}.json`,
    }),
    // Race APIs
    getFrontFavoriteAnalysis: builder.query<FrontFavoriteAnalysis, IdQueryArgs>(
      {
        query: ({ id }) => `race/${id}/front_favorite_analysis.json`,
      },
    ),
    // Start APIs
    getDetailedStart: builder.query<DetailedStart, IdQueryArgs>({
      query: ({ id }) => `start/${id}.json`,
    }),
    getStartStatistics: builder.query<StartStatistics, IdQueryArgs>({
      query: ({ id }) => `start/${id}/statistics.json`,
    }),
    getStartStatisticalTexts: builder.query<StartStatisticalTexts, IdQueryArgs>(
      {
        query: ({ id }) => `start/${id}/statistical-texts.json`,
      },
    ),
    getStartHeadTohead: builder.query<StartHeadTohead, IdQueryArgs>({
      query: ({ id }) => `start/${id}/head-to-head.json`,
    }),
    getGamePercentHistory: builder.query<
      GamePercentHistory,
      GamePercentHistoryQueryArgs
    >({
      query: ({ id, betType }) =>
        `start/${id}/game-percent-history/${betType}.json`,
      async onQueryStarted(
        { id, betType, legSlug },
        { dispatch, queryFulfilled },
      ) {
        // When the history is fetched some time may have passed since the round leg
        // was fetched and so the game percent and trend may have become outdated.
        // With the history we will also receive the latest precent and trend value
        // so here we update the game percent on the start in the cached round leg.
        try {
          const { data } = await queryFulfilled;
          dispatch(
            roundApi.util.updateQueryData('getRoundLeg', { legSlug }, draft => {
              draft.race.starts?.forEach(start => {
                if (start.id === id) {
                  start.game_percent.providers.ATG[betType] = data.game_percent;
                }
              });
            }),
          );
        } catch (error) {
          console.warn('Failed to update game percent on leg start:', error);
        }
      },
    }),
  }),
});

export const {
  useGetActiveRoundsQuery,
  useGetRoundQuery,
  useGetChangesQuery,
  useGetBettingTrendQuery,
  useGetCalendarQuery,
  useGetRoundLegQuery,
  useGetFrontFavoriteAnalysisQuery,
  useGetDetailedStartQuery,
  useGetStartStatisticsQuery,
  useGetStartStatisticalTextsQuery,
  useGetStartHeadToheadQuery,
  useGetGamePercentHistoryQuery,
  endpoints: {
    getActiveRounds,
    getRound,
    getChanges,
    getBettingTrend,
    getCalendar,
    getRoundLeg,
    getFrontFavoriteAnalysis,
    getDetailedStart,
    getStartStatistics,
    getStartStatisticalTexts,
    getStartHeadTohead,
    getGamePercentHistory,
  },
} = roundApi;
