import { createLocalStyledIcon } from '@trmediaab/zebra-icons';

import { default as InfoIcon } from './info.svg';

const Info = createLocalStyledIcon(InfoIcon);

Info.defaultProps = {
  width: 14,
  height: 'auto',
};

export default Info;
